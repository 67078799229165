/*
$.getScript("js/jquery.creditcardValidator.js");
$.getScript("js/jquery.maskedinput.min.js");

$.getScript("js/jquery.auto-complete.js");
*/
// $.getScript("js/jquery.creditcardValidator.js");

// Listen for orientation changes
window.addEventListener("orientationchange", function() {
    // Announce the new orientation number
}, false);

// Listen for resize changes
window.addEventListener("resize", function() {

}, false);


window.addEventListener("pageshow", function(event) {
    var historyTraversal = event.persisted || (typeof window.performance != "undefined" && window.performance.navigation.type === 2);
    if (historyTraversal) {
        NProgress.done();
    }
});

$(document).ready(function() {

    NProgress.done();
    // ByMC: For addonpayments
    // if($("#addonPayments").length) {
    //    $.ajax({
    //        type: 'POST',
    //        dataType: 'json',
    //        url: 'addonresquest/',
    //        data: {},
    //        success: function (data) {
    //            //RealexHpp.setHppUrl('https://hpp.addonpayments.com/pay');
    //            RealexHpp.setHppUrl('https://hpp.sandbox.addonpayments.com/pay');
    //            RealexHpp.init('addonPayments', 'addonresponse/', data);
    //        },
    //        error: function (XMLHttpRequest, textStatus, errorThrown) {
    //            //console.log(errorThrown);
    //        }
    //    });
    // }

    $("form[method=POST]").submit(function(e) {
        e.preventDefault();
    });

    $("form[method=GET]").submit(function(e) {
        e.preventDefault();
        $(this).find('input, select').each(function() {
            var attr = $(this).attr('required');
            var fill = 0;
            var fields = [];
            if (typeof attr !== typeof undefined && attr !== false) {
                // Element has this attribute
                if ($(this).val() == '') {
                    var name = $(this).attr('name');
                    fill += 1;
                    fields.push(name);
                }
            }

            if (fill > 0) {
                $('#message #text').html('Fill ' + fields.join(", ") + ' fields');
                $('#message').modal('show');
                e.preventDefault();
                return false;
            } else {
                NProgress.start();
            }
        });
    });

    $(window).bind('mousewheel DOMMouseScroll MozMousePixelScroll', function(event) {
        var scrollTop = $(document).scrollTop().valueOf();

        if (scrollTop > 100) {
            $('#header').addClass('top', 200);
        } else {
            $('#header').removeClass('top', 200);
        }
    });

    $('body').inspira();

    $('a[data-event="hover"]').hover(function() {
        var target = $(this).data('target');
        $(target).modal('show');

        $(target + ' .modal-wrapper').mouseenter(function() {

            $(this).mouseleave(function() {
                $(target).modal('hide');
            });

        });

        $(target).draggable({
            handle: ".modal-wrapper"
        });
        $('.modal-backdrop').css('display', 'none');

    });

    $('.navbar-collapse').on('show.bs.collapse', function() {
        $('a#register-box').css('visibility', 'hidden');
    });

    $('.navbar-collapse').on('hide.bs.collapse', function() {
        $('a#register-box').css('visibility', 'visible');
    });

    if (typeof $('body').find('> #carousel-slider')[0] != 'undefined') {
        var height = window.innerHeight;
        $('#carousel-slider .carousel-inner .item').css('height', height);


        $(window).resize(function() {
            var height = window.innerHeight;
            $('#carousel-slider .carousel-inner .item, .carousel-inner video').css('height', height);
            $('#carousel-slider .carousel-inner .item, .carousel-inner video').css('zoom', 1);
        });
    }


    $('div.modal').on('hidden.bs.modal', function() {
        var modal_in = $("body").find("div.modal.fade.in").length;
        if (modal_in > 0) {
            $('body').addClass('modal-open');
        } else {
            $('body').removeClass('modal-open');
        }
        $('.alert').hide();
        $('body.profile').attr('style', 'padding:0;');
    })

    $('div.modal').on('shown.bs.modal', function() {
        $('body').addClass('modal-open');
    })


    $('a.do-fb').on('click', function(evnt) {
        var discountCode = $(this).parents('div.modal').find('input[name=access_code]').val();
        //console.log(typeof discountCode);
        if (typeof discountCode == 'undefined') {
            window.location.href = $(this).attr('href');
        } else if (typeof discountCode == 'string' && discountCode != '') {
            window.location.href = $(this).attr('href') + '/' + discountCode;
        }
        return false;
    });

    $('.login-form input[type="password"]').keypress(function(e) {
        if (e.keyCode == 13)
            $('.login-form [data-role="submit"]').click();
    });

    $('*[data-toggle="tooltip"]').tooltip();


    $('.form-control').on('keypress', function(e) {
        if (e.keyCode == 13)
            e.preventDefault();
    });

    $('select.room-type').on('change', function() {
        var txt = $("select.room-type option:selected").text();
        $('span.rooms-quantity').text(txt);
    });

    $('.plus, .minus').on('click', function() {
        var button = $(this);
        var input = button.siblings().closest('input[type=hidden]');
        var oldValue = input.val();

        if (button.hasClass('plus')) {
            var newVal = parseFloat(oldValue) + 1;
        } else {
            // Don't allow decrementing below zero
            if (oldValue > 1) {
                var newVal = parseFloat(oldValue) - 1;
            } else {
                newVal = 1;
            }

        }

        var name = input.attr('name') + '-quantity';
        $('span.' + name).text(newVal);
        button.parent().find("input").val(newVal);

    });

});

(function($) {
    var feature = {
        _init: function() {
            // 			NProgress.start();
            setTimeout(function() {
                NProgress.done();
                $('.fade').removeClass('out');
            }, 1000);

            return this.each(function() {
                var _this = $(this);
                var _buttons = _this.find('[data-role=change],[data-role=submit],input[type="submit"],[data-role="load-modal"]');
                var _remove_btn = _this.find('[data-role="remove"]');
                var _page_redirect = _this.find('[data-role=redirect]');
                var _refresh_section = _this.find('[data-role=refresh]');
                var _select_change = _this.find('select.select-change');
                var _email = _this.find('input[type="email"].validate-email');
                var _card = _this.find('input#card_number');
                var _masked = _this.find('input[data-mask-type]');
                var _fund = _this.find('input[name="fondo"]');
                var _language = _this.find('select[id="language"]');
                var _radio_bonus = _this.find('#bonus input[type="radio"]');
                var _anchor = $(this).find('a[data-anchor]');
                var _captcha = _this.find('.g-recaptcha');
                var _window = $(window);
                var _paginator = _this.find('a[data-role="paginator"],input[name="entries"], select[name="entries"]');
                var _number = _this.find('input[type=number]');
                var _search = _this.find('input[data-role="search"],input[name="entries"]');
                var _autocomplete = _this.find('#autocomplete');
                var _autocompletewl = _this.find('#autocompletewl');
                var _picker = _this.find('.form_datetime');
                var _fit_container = _this.find('.fit-content');
                var _lawo = _this.find('.lawo');
                var _search_by = _this.find('.search_by');
                var _wlsearch = _this.find('#wl-search');
                var _checkfilter = _this.find('#addfilt');
                var _filtcancel = _this.find('#filtcancel');
                var _filtlabel = _this.find('.filtlabel');
                var _customfilt = _this.find('.customfilt');
                var _savefilt = _this.find('#savefilt');
                var _aview = _this.find('.log-view');
                var _aremove = _this.find('.log-remove');
                var _aedit = _this.find('.log-edit');
                var _combobox = _this.find('#combobox');
                var _logsearch = _this.find('#logSearch');
                var _logtable = _this.find('#example500');
                var _validateLogSearch = _this.find('#logSearch');
                var _retag = _this.find("[name='retag']");

                checkSearch(_search_by.val());


                feature._set_change(_buttons);
                feature._set_refresh(_refresh_section);
                feature._on_change_select(_select_change);
                feature._on_change_email(_email);
                feature._apply_card_validation(_card);
                feature._apply_masked_input(_masked);
                feature._enable_fund(_fund);
                feature._change_language(_language);
                feature._apply_bonus(_radio_bonus);
                feature._apply_anchor(_anchor);
                feature._apply_resize(_captcha);
                feature._on_window_change(_window);
                feature._apply_paginate(_paginator);
                feature._apply_number_change(_number);
                feature._apply_search(_search);
                feature._apply_autocomplete(_autocomplete);
                feature._apply_autocompletewl(_autocompletewl);
                feature._apply_datepicker(_picker);
                feature._do_widgets(_this);
                feature._do_styles(_fit_container);
                feature._do_redirect(_page_redirect);
                feature._remove(_remove_btn);
                feature._vallawo(_lawo);
                feature._searchCheck(_search_by);
                feature._wlsearch(_wlsearch);
                feature._checkfilter(_checkfilter);
                feature._filtcancel(_filtcancel);
                feature._filtlabel(_filtlabel);
                feature._customfilt(_customfilt);
                feature._savefilt(_savefilt);
                feature._aview(_aview);
                feature._aremove(_aremove);
                feature._aedit(_aedit);
                feature._combobox(_combobox);
                feature._logsearch(_logsearch);
                feature._logtable(_logtable);
                feature._validateLogSearch(_validateLogSearch);
                feature._retag(_retag);

                $.ajaxSetup({ headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'), 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Headers': 'X-Requested-With' } });

            });
        },
        _set_actions: function(element) {
            feature._do_widgets(element);
            feature._set_change(element.find('[data-role=submit],[data-role=change],input[type="submit"],[data-role="load-modal"]'));
            feature._set_refresh(element.find('[data-role=refresh]'));
            feature._on_change_select(element.find('select.select-change'));
            feature._apply_card_validation(element.find('input#card_number'));
            feature._on_change_email(element.find('input[type="email"].validate-email'));
            feature._apply_masked_input(element.find('input[data-mask-type]'));
            feature._enable_fund(element.find('input[name="fondo"]'));
            feature._apply_bonus(element.find('#bonus input[type="radio"]'));
            feature._apply_resize(element.find('.g-recaptcha'));
            feature._apply_paginate(element.find('a[data-role="paginator"],input[name="entries"], select[name="entries"]'));
            feature._apply_search(element.find('input[data-role="search"],input[name="entries"]'));
            feature._apply_number_change(element.find('input[type=number]'));
            feature._do_widgets(element);
            feature._do_styles(element.find('.fit-content'));
            feature._do_redirect(element.find('[data-role=redirect]'));
            feature._remove(element.find('[data-role="remove"]'));
            feature._apply_autocomplete(element.find('#autocomplete'));
            feature._apply_autocompletewl(element.find('#autocompletewl'));
            feature._apply_datepicker(element.find('.form_datetime'));
            feature._vallawo(element.find('.lawo'));
            feature._searchCheck(element.find('.search_by'));
            feature._wlsearch(element.find('#wl-search'));
            feature._checkfilter(element.find('#addfilt'));
            feature._filtcancel(element.find('#filtcancel'));
            feature._filtlabel(element.find('.filtlabel'));
            feature._customfilt(element.find('.customfilt'));
            feature._savefilt(element.find('#savefilt'));
            feature._aview(element.find('.log-view'));
            feature._aremove(element.find('.log-remove'));
            feature._aedit(element.find('.log-edit'));
            feature._combobox(element.find('#combobox'));
            feature._logsearch(element.find('#logSearch'));
            feature._logtable(element.find('example500'));
            feature._validateLogSearch(element.find('#logSearch'));
            feature._retag(element.find("[name='retag']"));



            feature._hover_flag = false;

            $('a[data-event="hover"]').hover(function() {
                var target = $(this).data('target');
                $(target).modal('show');

                $(target + ' .modal-wrapper').mouseenter(function() {

                    $(this).mouseleave(function() {
                        $(target).modal('hide');
                    });

                });

                $(target).draggable({
                    handle: ".modal-wrapper"
                });
                $('.modal-backdrop').css('display', 'none');

            });

        },

        _do_redirect: function(a) {
            a.bind('click', function(e) {
                NProgress.start();
                // setTimeout(function() { NProgress.start(); }, 1000);

                var _this = $(this);
                var _target = _this.data('target');

                var _route = _this.data('route');

                if (!_route)
                    _route = _this.attr('href');

                feature._redirect(_route);
                e.preventDefault();
            });
        },
        _wlsearch: function(element) {
            element.click(function() {
                $("#form_wl_search").submit();
            });
        },
        _logtable: function(element) {
            $(document).ready(function() {
                $('#example500').DataTable({
                    searching: false,
                    information: false,
                    ordering: false,
                    "dom": '<"top">rt<"bottom"flp><"clear">'
                });
            });
        },
        _validateLogSearch: function(element) {
            var count = $('#alltags > span').length;
            var par = $('.tagsfilter').find('#logSearch');
            countTags(count, par);
        },
        _retag: function(element) {
            $(document).ready(function() {
                var wea = $("[name='retag']").val();
                var b = Array();
                var c = Array();
                var keyword_filter = '';
                var type_filter = '';
                var are_filter = 0;
                //first phase
                var a = wea.split('&');
                $.each(a, function(index, value) {
                    var e = value.split('=');
                    b.push(e);
                });
                if (wea != 'none' || wea == '') {
                    for (var i = 0; i < b.length; i++) {
                        //NOTE:  keyword_filter b[i][1];
                        //NOTE :g[0] = type_filter;
                        //NOTE :g[1] = are_filter;
                        var g = b[i][0].split('[');
                        if (g[1] == 'eq]') {
                            are_filter = 1;
                        }
                        var serializedArr = '[{"name":"type_filter","value":"' + g[0] + '"},{"name":"are_filter","value":"' + are_filter + '"},{"name":"keyword_filter","value":"' + b[i][1] + '"}]';
                        var tag = "<span id='filter" + i + "' class='badge bgsize row' style='background-color: #2a65b7!important; margin-left:18px;'><div id='label" + i + "'><strong>" + g[0] + "</strong></div><div id='cust" + i + "'><a><i style='color:white; margin-left:5px; margin-right:5px;' class='glyphicon glyphicon-eye-open log-view'></i></a><a><span style='color:white; margin-left:5px; margin-right:5px;' class='glyphicon glyphicon-edit log-edit'></span></a><a><i style='color:white; margin-left:5px; margin-right:5px;' class='glyphicon glyphicon-trash log-remove'></i></a></div><input type='hidden' name='keywordsdata' value='" + serializedArr + "'></span>";
                        $('#alltags').append(tag);
                        $('#label' + i).addClass('filtlabel');
                        $('#cust' + i).addClass('customfilt');

                    }
                }
            });
        },
        _logsearch: function(element) {
            $(document).on('click', '#logSearch', function() {
                var largo = $('#alltags > span').length;
                var jsoninfo = new Array();
                var apiinfo = new Array();
                for (var i = 0; i < largo; i++) {
                    var j = i + 1;
                    var info = $('#filter' + j).find("[name='keywordsdata']").val();
                    info = JSON.parse(info);
                    if (info[1]['value'] == 0) {
                        info[1]['value'] = '[ne]';
                    } else {
                        info[1]['value'] = '[eq]';
                    }
                    jsoninfo[i] = info[0]['value'] + info[1]['value'] + ":'" + info[2]['value'] + "'";
                    apiinfo[i] = info[0]['value'] + info[1]['value'] + "=" + info[2]['value'];
                }


                $("[name='datacontent']").val(apiinfo);
                var w = $("[name='datacontent']").val();
                var rtr = w + ',';
                var rtr = w.split(",").join("&");
                $("[name='datacontent']").val(rtr);
                var url = window.location.origin;
                rtr = $("[name='datacontent']").val();
                var complete = url + '/admin/log-review?' + rtr;

                window.location.href = complete;

            });
        },

        _combobox: function(element) {
            $(document).ready(function() {
                $('.js-example-basic-single').select2();
            });
        },
        _savefilt: function(element) {
            $(element).click(function() {

                var datafilter = $("[name='type_filter'],[name='are_filter'],[name='keyword_filter']").serializeArray();
                var serializedArr = JSON.stringify($("[name='type_filter'],[name='are_filter'],[name='keyword_filter']").serializeArray());
                var name = $("[name='tag_filter']").val();
                if (name == '') { name = $("[name='type_filter'] option:selected").text(); }

                if ($("[name='idfiltervalue']").val() == '') {
                    var num = $('#alltags > span').length;
                    if (num == 0) {
                        var filnum = num + 1;
                    } else {
                        var aidi = $('#alltags > span:last').attr('id');
                        var numbers = aidi.match(/\d+/g).map(Number);
                        console.log(numbers[0]);
                        var filnum = numbers[0] + 1;
                    }
                    var tag = "<span id='filter" + filnum + "' class='badge bgsize row' style='background-color: #2a65b7!important; margin-left:18px;'><div id='label" + filnum + "'><strong>" + name + "</strong></div><div id='cust" + filnum + "'><a><i style='color:white; margin-left:5px; margin-right:5px;' class='glyphicon glyphicon-eye-open log-view'></i></a><a><span style='color:white; margin-left:5px; margin-right:5px;' class='glyphicon glyphicon-edit log-edit'></span></a><a><i style='color:white; margin-left:5px; margin-right:5px;' class='glyphicon glyphicon-trash log-remove'></i></a></div><input type='hidden' name='keywordsdata' value='" + serializedArr + "'></span>";
                    $('#alltags').append(tag);
                    $('#label' + filnum).addClass('filtlabel');
                    $('#cust' + filnum).addClass('customfilt');
                } else {
                    var serch = $("[name='idfiltervalue']").val();
                    $('#' + serch).find("[name='keywordsdata']").val('');
                    $('#' + serch).find("[name='keywordsdata']").val(serializedArr);
                    $('#' + serch).find("strong").text('');
                    $('#' + serch).find("strong").text(name);
                }
                $("[name='type_filter'],[name='keyword_filter'],[name='tag_filter'],[name='idfiltervalue']").val('');
                $('#combobox').val('').trigger('change');
                $('#filterdata').hide();

                $('#logSearch').attr('disabled', false);
                $('#logSearch').css('background-color', '#465664!important');
                $('#logSearch').css('cursor', 'pointer');

            });
        },
        _aedit: function(element) {
            $(document).on('click', '.log-edit', function() {
                var a = $(this).parent().parent().parent().attr('id');
                var c = $('#' + a).find("[name='keywordsdata']").val();
                var name = $('#' + a).find('strong').text();
                c = JSON.parse(c);
                $("[name='type_filter']").val(c[0]['value']);
                $("[name='type_filter'],#combobox").val(c[0]['value']).trigger('change');
                $("[name='are_filter']").val(c[1]['value']);
                $("[name='keyword_filter']").val(c[2]['value']);
                $("[name='idfiltervalue']").val(a);
                if (name != c[0]['value']) {
                    $("[name='tag_filter']").val(name);
                }
                $('#filterdata').show();
            });
        },
        _aview: function(element) {
            $(document).on('click', '.log-view', function() {
                var a = $(this).parent().parent().parent().attr('id');
                var c = $('#' + a).find("[name='keywordsdata']").val();
                var name = $('#' + a).find('strong').text();
                c = JSON.parse(c);
                $("[name='type_filter'],#combobox,js-example-basic-single").val(c[0]['value']);
                $("[name='type_filter'],#combobox").val(c[0]['value']).trigger('change');
                $("[name='are_filter']").val(c[1]['value']);
                $("[name='keyword_filter']").val(c[2]['value']);
                if (name != c[0]['value']) {
                    $("[name='tag_filter']").val(name);
                }
                $('#savefilt').hide();
                $("[name='keyword_filter'],[name='tag_filter']").attr('readonly', true);
                $("[name='type_filter'],[name='are_filter']").attr('disabled', true);
                $('#filtcancel').val('Close');
                $('#filtcancel').css('background-color', '#465664!important');
                $('#filtcancel').css('color', 'white');
                $('#filterdata').show();
            });
        },
        _aremove: function(element) {
            $(document).on('click', '.log-remove', function() {
                var a = $(this).parent().parent().parent().attr('id');
                $('#' + a).remove();
                var count = $('#alltags > span').length;
                var par = $('.tagsfilter').find('#logSearch');
                countTags(count, par);
            });
        },
        _filtlabel: function(element) {
            $(document).on('mouseenter', '.filtlabel', function() {
                $(this).css('transition-duration', '0.5s');
                $(this).hide();
                $(this).siblings('.customfilt').show();
            });
        },
        _customfilt: function(element) {
            $(document).on('mouseleave', '.customfilt', function() {
                $(this).css('transition-duration', '0.5s');
                $(this).hide();
                $(this).siblings('.filtlabel').show();
            });
        },
        _do_styles: function(p) {
            $.each(p, function() {
                var _this = $(this);
                var _thumbs = _this.find('.fit-in');
                var _height = _this.height();
                _thumbs.css('min-height', _this.height());
            });
        },
        _do_widgets: function(p) {
            $.each(p.find('table, :input, a, span'), function() {
                var _element = $(this);

                if (_element.is('input[type="file"]')) {
                    feature._preview_input(_element);
                } else if (_element.is('span.pick')) {
                    feature._pick_file(_element);
                }
            });
        },
        _checkfilter: function(element) {
            $(element).click(function() {
                if ($('#filterdata').is(":visible")) {
                    $("[name='type_filter'],[name='keyword_filter'],[name='tag_filter'],[name='idfiltervalue']").val('');
                    $("[name='type_filter'],#combobox").val('').trigger('change');
                    $('#filterdata').hide();
                } else { $('#filterdata').show(); }

            });
        },
        _filtcancel: function(element) {

            $(document).on('click', '#filtcancel', function() {

                $("[name='type_filter'],[name='keyword_filter'],[name='tag_filter'],[name='idfiltervalue']").val('');
                $("[name='type_filter'],#combobox").val('').trigger('change');
                if ($(element).val() == 'Close') {
                    $("[name='keyword_filter'],[name='tag_filter']").attr('readonly', false);
                    $("[name='type_filter'],[name='are_filter']").attr('disabled', false);
                    $('#filtcancel').css('background-color', 'white');
                    $('#filtcancel').css('color', '#465664!important');
                    $('#savefilt').show();
                    $('#filtcancel').val('Cancel');
                }
                $('#filterdata').hide();
            });
        },
        _pick_file: function(element) {
            element.unbind().click(function(element) {
                $('input[type=file]').click();
                element.preventDefault();
                return;
            });
        },
        _hover_flag: false,
        _searchCheck: function(element) {
            element.change(function() {
                var filter_by = element.val();
                checkSearch(filter_by);
            });

        },
        _vallawo: function(element) {
            element.keypress(function(event) {
                Validate(event);
            });
            element.keyup(function(event) {
                $('.notify').addClass('note');
                var wea = element.val();
                var stuff = wea.substring(0, 1);
                if (wea = !'' || wea != null) {
                    $('.notify').removeClass('note');
                    if (stuff > 0) {
                        stuff = 'num';
                    }
                    switch (stuff) {
                        case '+':
                            $('.notify').css('background-color', '').css('background-color', 'green');
                            $('#price_operation').val('add');
                            $('.notify').text('Add');
                            break;
                        case '-':
                            $('.notify').css('background-color', '').css('background-color', 'orange');
                            $('#price_operation').val('minus');
                            $('.notify').text('Minus');
                            break;
                        case 'num':
                            $('.notify').css('background-color', '').css('background-color', 'blue');
                            $('#price_operation').val('fixed');
                            $('.notify').text('Fixed');
                            break;
                        default:
                            $('.notify').css('background-color', '');
                            $('.notify').addClass('note');
                    }
                }
            });
        },
        _preview_input: function(p) {
            p.unbind().on("change", function(event) {
                var __this = $(this);
                var route = __this.data('route');
                var file = __this.prop('files')[0];
                var __img = __this.siblings().find('img');;
                console.log(file);
                if (!window.File && !window.FileReader && !window.FileList && !window.Blob) {
                    alert('Seems you have an older browser, please update to get the most of Inspira!.');
                    return;
                } else {
                    var rd = new FileReader();
                    rd.onload = function(e) {




                        object = {};
                        object.filename = file.name;
                        object.data = e.target.result;
                        object.temp_path = URL.createObjectURL(event.target.files[0]);



                        NProgress.start();
                        $.ajax({
                            url: route,
                            type: 'POST',
                            data: { filename: object.filename, data: object.data, temp_path: object.temp_path },
                            success: function(_ajax_response, status, xhr) {
                                if (_ajax_response.message) {
                                    $('#message #text').html(_ajax_response.message);
                                    $('#message').modal('show');
                                    $('#message').on('hidden.bs.modal', function() {
                                        feature._redirect(_ajax_response.redirect);
                                    });
                                    return false;
                                }

                                feature._redirect(_ajax_response.redirect);
                            }
                        });

                        if (file.type == "image/jpeg" || file.type == "image/png") {
                            __img.attr('src', e.target.result);
                            __img.attr('src', e.target.result).fadeOut();
                            __img.attr('src', e.target.result).fadeIn();

                            __img.addClass('img-select').imgAreaSelect({
                                aspectRatio: '1:1',
                                onSelectEnd: function(img, selection) {
                                    if (!selection.width || !selection.height) {
                                        return;
                                    }
                                    $('#thumbnail_x1').val(selection.x1);
                                    $('#thumbnail_y1').val(selection.y1);
                                    $('#thumbnail_x2').val(selection.x2);
                                    $('#thumbnail_y2').val(selection.y2);
                                    $('#thumbnail_width').val(selection.width);
                                    $('#thumbnail_height').val(selection.height);
                                    $('#img_width').val(__img.width());
                                    $('#img_height').val(__img.height());
                                    $('#image').val(e.target.result);
                                },
                                onInit: function() {
                                    $('#thumbnail_x1').val(0);
                                    $('#thumbnail_y1').val(0);
                                    $('#thumbnail_x2').val(__img.width());
                                    $('#thumbnail_y2').val(__img.height());
                                    $('#thumbnail_width').val(__img.height());
                                    $('#thumbnail_height').val(__img.height());
                                    $('#img_width').val(__img.width());
                                    $('#img_height').val(__img.height());
                                    $('#image').val(e.target.result);
                                }
                            });


                        }



                        // files.push(object);

                        /*__img.fadeOut(function(){


							if(this.height > 960){

								$('#message #text').html('The picture is too Big!');
								$('#message').modal('show');

								$(this).fadeIn();

							}else{

								$(this).attr('src', e.target.result);

								$(this).attr('src', e.target.result ).fadeOut();
								$(this).attr('src', e.target.result ).fadeIn();


 							}
 							NProgress.done();
						});
						__img.addClass('img-select').imgAreaSelect({
							aspectRatio: '1:1',
							onSelectEnd: function (img, selection) {
								if (!selection.width || !selection.height) {
									return;
								}
								$('#thumbnail_x1').val(selection.x1);
								$('#thumbnail_y1').val(selection.y1);
								$('#thumbnail_x2').val(selection.x2);
								$('#thumbnail_y2').val(selection.y2);
								$('#thumbnail_width').val(selection.width);
								$('#thumbnail_height').val(selection.height);
								$('#img_width').val(__img.width());
								$('#img_height').val(__img.height());
								$('#image').val(e.target.result);
							},
							onInit: function(){
								$('#thumbnail_x1').val(0);
								$('#thumbnail_y1').val(0);
								$('#thumbnail_x2').val(__img.width());
								$('#thumbnail_y2').val(__img.height());
								$('#thumbnail_width').val(__img.height());
								$('#thumbnail_height').val(__img.height());
								$('#img_width').val(__img.width());
								$('#img_height').val(__img.height());
								$('#image').val(e.target.result);
							}
						});
						if(__img.width() > 960){
							alert('upload another picture please');
						}

						*/

                    }

                    rd.onloadend = function(e) {
                        NProgress.done();
                    }
                    rd.readAsDataURL(file);
                    event.preventDefault();
                }
            });

        },
        _apply_datepicker: function(element) {
            var format = $('#date_format').val();
            var date_format = typeof format == 'string' ? format : 'mm/dd/yyyy';

            element.datepicker({ format: date_format, pickTime: false, autoclose: true, startDate: '-1d' });
        },
        _apply_autocompletewl: function(element) {

            if (element.length > 0) {
                var _this = $(this);
                var _route = _this.data('route');
                var _container = element.data('container');
                var _ul = [];

                var formatName = function(name, parent, city) {
                    return toTitle(name);
                    if (typeof parent == 'undefined') {
                        if (typeof city == 'undefined')
                            return toTitle(name);
                        return toTitle(city) + ' / ' + toTitle(name);
                    } else {
                        return toTitle(name) + ' / ' + toTitle(parent);

                    }
                };

                var toTitle = function(str) {
                    return str;
                    return str.replace(/\w\S*/g, function(txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });

                }

                var selected = false;
                var _del = 0;
                $(element).autocomplete({
                    source: function(request, response) {
                        $.ajax({
                            url: "/admin/wl/search",
                            dataType: "json",
                            data: {
                                term: request.term
                            },
                            success: function(data) {
                                response(data);
                            }
                        });
                    },
                    minLength: 1,
                    appendTo: typeof _container == 'string' ? $(_container).children(":first") : 'body',
                    messages: {
                        noResults: function(count) {
                            $('.destination-input').find('i').removeAttr('class').html('').hide();
                            $('.ui-helper-hidden-accessible').text('Sorry, no resorts in that area.').removeAttr('class');

                        },
                        results: function(items) {
                            $('span[role="status"]').addClass('ui-helper-hidden-accessible');
                        }
                    },
                    select: function(event, ui) {
                        var response_box = $(this).parents('[data-role="response"]').last();
                        selected = true;
                        //poner el id, y categoria en un hidden.
                        response_box.find('input[name=destination_id]').val(ui.item.resort_id);
                        response_box.find('input[name=category]').val('resort');
                        ui.item.value = formatName(ui.item.resort_name);
                        $('.destination-input').find('i').attr('class', 'resort').html('RESORT').show();
                    },
                    autoSelect: true,
                    autoFocus: true,
                    html: true,
                    response: function(event, ui) {
                        var response_box = $(this).parents('[data-role="response"]').last();
                        selected = false;

                        var first = ui.content[0];
                        if (first) {
                            response_box.find('input[name=destination_id]').val(first.resort_id);
                            response_box.find('input[name=category]').val('resort');
                        } else {
                            response_box.find('input[name=destination_id]').val('');
                            response_box.find('input[name=category]').val('');
                        }
                    }
                }).bind('keyup', function(e) {
                    var response_box = $(this).parents('[data-role="response"]').last();
                    response_box.find('input:not(#search-switch):checkbox').removeAttr('checked');
                    if (e.keyCode == 46 || e.keyCode == 8) { _del++; } else { _del = 0; }

                    if (this.value == '' || _del >= 1) {
                        response_box.find('input[name=destination_id]').val('');
                        response_box.find('input[name=category]').val('');

                        $('.destination-input').find('i').removeAttr('class').html('').hide();
                        $('span[role="status"]').addClass('ui-helper-hidden-accessible');
                    } else if (e.keyCode != 46 || e.keyCode != 8) {
                        $('.destination.ui-autocomplete-input').trigger("keydown", { which: 39 });
                    }
                }).data("ui-autocomplete")._renderItem = function(ul, item) {
                    _ul.push(item);
                    return $("<li></li>")

                    .data("ui-autocomplete-item", item)

                    .append("<a><i class='resort'>RESORT</i>" + formatName(item.resort_name) + "</a>")

                    .appendTo(ul);
                };
            }
        },
        _apply_autocomplete: function(element) {

            if (element.length > 0) {
                var _this = $(this);
                //element.find('#autocompletewl')
                var _route = _this.data('route');
                var _container = element.data('container');
                var _ul = [];

                var formatName = function(name, parent, city) {
                    return toTitle(name);
                    if (typeof parent == 'undefined') {
                        if (typeof city == 'undefined')
                            return toTitle(name);
                        return toTitle(city) + ' / ' + toTitle(name);
                    } else {
                        return toTitle(name) + ' / ' + toTitle(parent);

                    }
                };

                var toTitle = function(str) {
                    return str;
                    return str.replace(/\w\S*/g, function(txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });

                }

                var selected = false;
                var _del = 0;
                var destinationscache = {};
                $(element).autocomplete({
                    delay: 80,
                    source: function(request, response) {
                        if (!(request.term in destinationscache)) {
                            destinationscache[request.term] = $.ajax({
                                url: "/destination/search",
                                dataType: "json",
                                data: {
                                    term: request.term
                                }
                                /*,
                                						          success: function( data ) {
                                						            response( data );
                                								}*/
                            });
                        }
                        destinationscache[request.term].done(function(json) {
                            response(json);
                        });
                    },
                    minLength: 1,
                    appendTo: typeof _container == 'string' ? $(_container).children(":first") : 'body',
                    messages: {
                        noResults: function(count) {
                            $('.destination-input').find('i').removeAttr('class').html('').hide();
                            $('.ui-helper-hidden-accessible').text('Sorry, no resorts in that area.').removeAttr('class');

                        },
                        results: function(items) {
                            $('span[role="status"]').addClass('ui-helper-hidden-accessible');
                        }
                    },
                    select: function(event, ui) {
                        var response_box = $(this).parents('[data-role="response"]').last();
                        selected = true;
                        //poner el id, y categoria en un hidden.
                        response_box.find('input[name=destination_id]').val(ui.item.destination_id);
                        response_box.find('input[name=category]').val(ui.item.category);
                        ui.item.value = formatName(ui.item.destination_name, ui.item.parent_city);
                        $('.destination-input').find('i').attr('class', ui.item.category).html(ui.item.category).show();
                    },
                    autoSelect: true,
                    autoFocus: true,
                    html: true,
                    response: function(event, ui) {
                        var response_box = $(this).parents('[data-role="response"]').last();
                        selected = false;

                        var first = ui.content[0];
                        if (first) {
                            response_box.find('input[name=destination_id]').val(first.destination_id);
                            response_box.find('input[name=category]').val(first.category);
                        } else {
                            response_box.find('input[name=destination_id]').val('');
                            response_box.find('input[name=category]').val('');
                        }
                    }
                }).bind('keyup', function(e) {
                    var response_box = $(this).parents('[data-role="response"]').last();
                    response_box.find('input:not(#search-switch):checkbox').removeAttr('checked');
                    if (e.keyCode == 46 || e.keyCode == 8) { _del++; } else { _del = 0; }

                    if (this.value == '' || _del >= 1) {
                        response_box.find('input[name=destination_id]').val('');
                        response_box.find('input[name=category]').val('');

                        $('.destination-input').find('i').removeAttr('class').html('').hide();
                        $('span[role="status"]').addClass('ui-helper-hidden-accessible');
                    } else if (e.keyCode != 46 || e.keyCode != 8) {
                        $('.destination.ui-autocomplete-input').trigger("keydown", { which: 39 });

                        // $.ajax({url:'/google-suggestion', data: { 'param' : $(this).val() } ,  type: 'POST'}).done(function(_ajax_response){
                        // 	if(_ajax_response.word.length > 0 ){
                        // 		$('.destination.ui-autocomplete-input').val(_ajax_response.word).trigger("enterKey");;
                        // 		$('.destination.ui-autocomplete-input').trigger("keydown", {which: 39});
                        // 	}
                        // });
                    }
                }).data("ui-autocomplete")._renderItem = function(ul, item) {
                    _ul.push(item);
                    return $("<li></li>")

                    .data("ui-autocomplete-item", item)

                    .append("<a><i class=" + item.category + ">" + item.category + "</i>" + formatName(item.destination_name, item.parent_city, item.city_in) + "</a>")

                    .appendTo(ul);
                };
            }
        },
        _apply_search: function(element) {
            element.on('keyup', function(evnt) {
                var _this = $(this);
                var _response = _this.closest('div[data-role=response]');

                var _value = _this.closest('input[name=search]').val();
                var _entries = _response.find('[name=entries]').val();

                var _form = _this.parents('form');
                var _route = _this.data('route');
                var _append = (typeof _this.data('append') == 'string') ? _this.data('append') : '#' + _this.closest('div.modal').attr('id');
                var _data = {};

                if (evnt.keyCode == 13) {

                    _data = { 'search': _value, 'entries': _entries };

                    NProgress.start();
                    $.ajax({ url: _route, data: _data, type: 'POST' }).done(function(_ajax_response) {

                        if (_ajax_response.html) {
                            if (_append == 'no-modal') {
                                $(_response).html(feature._htmlDecode(_ajax_response.html));
                                feature._set_actions(_response);
                            } else {

                                $(_append + ' div.modal-wrapper').html(feature._htmlDecode(_ajax_response.html));
                                $(_append).modal('show');
                                feature._set_actions($(_append + ' div.modal-wrapper'));
                            }

                            NProgress.done();

                            return false;

                        } else if (_ajax_response) {
                            if (typeof _ajax_response == 'string') {
                                NProgress.done();
                                _response.html(_ajax_response);
                                feature._set_actions(_response);
                                return;
                            }
                        }
                    });
                }
            });
        },
        _apply_paginate: function(element) {

            element.each(function() {
                // console.log($(this).attr('type'));
                var bind_event = 'change';
                if (!$(this).is("select")) {
                    bind_event = 'click';
                }

                $(this).bind(bind_event, function(evnt) {
                    evnt.stopPropagation();
                    var _this = $(this);
                    var _response = _this.closest('div[data-role=response]');
                    var _form = _this.parents('form');
                    var _route = _this.data('route');
                    var _append = (typeof _this.data('append') == 'string') ? _this.data('append') : '#message';

                    var _data = { 'entries': _this.attr('name') == 'entries' ? _this.find("option:selected").val() : 10 };

                    NProgress.start();
                    $.ajax({ url: _route, data: _data, type: 'POST' }).done(function(_ajax_response) {

                        if (_ajax_response.html) {

                            if (_append == 'no-modal') {
                                $(_response).html(feature._htmlDecode(_ajax_response.html));
                                NProgress.done();
                                feature._set_actions(_response);
                                return false;
                            }

                            if (_append == 'response') {
                                $(_response).html(feature._htmlDecode(_ajax_response.html));
                                feature._set_actions($(_response));
                                NProgress.done();
                            } else {
                                $(_append + ' div.modal-wrapper').html(feature._htmlDecode(_ajax_response.html));
                                $(_append).modal('show');
                            }

                            feature._set_actions($(_append + ' div.modal-wrapper'));
                            NProgress.done();

                            return false;
                        }
                    });

                });
            });
        },
        _on_window_change: function(element) {
            if ($('#captcha').length > 0) {
                element.on('resize', function() {
                    location.reload();
                });

                element.on('orientationchange', function(evt) {
                    location.reload();
                });
            }
        },
        _apply_resize: function(element) {
            var width = element.parent().width();

            var scale = width / 302;

            var margin = (width - 302);


            if (scale < 1) {
                element.css('transform', 'scale(' + scale + ')');
                element.css('-webkit-transform', 'scale(' + scale + ')');
                element.css('transform-origin', '0 0');
                element.css('-webkit-transform-origin', '0 0');
            } else {
                var margin2 = (margin / 2);
                element.css('margin-left', margin2 + 'px');
            }
        },
        _interval: '',
        _set_refresh: function(element) {
            $.each(element, function() {
                var _this = $(this);

                var _response = _this.closest('div[data-role=response]');
                var _append = '#almostExpired';
                var _route = _this.data('route');
                var _data = {};

                var ajaxRequest = function() {
                    $.ajax({ url: _route, data: _data, type: 'POST' }).done(function(_ajax_response) {
                        if (_ajax_response.html) {
                            _response.html(feature._htmlDecode(_ajax_response.html));
                            if (_ajax_response.redirect) {
                                var _body = $(_append + ' div.modal-wrapper');

                                _body.html(feature._htmlDecode(_ajax_response.message));

                                $('#almostExpired').modal('show');
                                feature._set_actions(_body);



                                // 									feature._set_actions( _body );

                                /*
                                									$(_append).on('show.bs.modal', function () {
                                										$('#almostExpired').modal('show');
                                										console.log('Cleared?');
                                									});
                                */

                                /*
                                									if(!feature._hover_flag){ $(_append).modal('show'); feature._hover_flag = true; }
                                										$(_append).on('hidden.bs.modal', function () {
                                											console.log('hidden?');
                                											feature._hover_flag = true;
                                											window.location.href = '#';
                                										});

                                										$(_append).on('show.bs.modal', function () {
                                											clearInterval(feature._interval);
                                											console.log('Cleared?');
                                										});*/
                            }

                            feature._set_change(_response.find('a[data-role="load-modal"]'));
                            //	$('.modal-backdrop').remove();
                            // 								feature._set_actions( _response );
                        } else {
                            if (_ajax_response.redirect) {
                                feature._redirect(_ajax_response.redirect);
                            }
                        }

                    }).fail(function() {
                        $.ajax({ url: '/fails/500/modal', type: 'POST' }).done(function(_ajax_response) {
                            $('#message #text').html(feature._htmlDecode(_ajax_response.html));
                            $('#message').modal('show');
                        });
                    });
                }

                var now = new Date();
                var delay = 15 * 1000; // 1 min in msec
                var start = delay - (now.getSeconds()) * 1000 + now.getMilliseconds();


                setTimeout(function() {
                    setInterval(ajaxRequest, delay);
                    ajaxRequest();

                }, start);
            });
        },
        _set_change: function(element) {
            $.each(element, function() {
                var _this = $(this);
                var _on = 'click';

                if (_this.is('select')) {
                    _on = 'change';
                }

                if (_this.attr('data-event')) {
                    if (_this.attr('data-event') == 'wallet') {
                        _on = 'change';
                    } else {
                        _on = 'click, ' + _this.data('event');
                    }
                }

                _this.bind(_on, function(e) {


                    var _this = $(this);

                    var _response = _this.closest('div[data-role=response]');

                    //var _form = _response.parents('form');
                    var _form = _this.parents('form');



                    var _route = _this.data('route');
                    var _append = (typeof _this.data('append') == 'string') ? _this.data('append') : '#message';
                    var _body = '';
                    _this.attr('data-route', _route);
                    var _data = {};

                    if (typeof _route != 'string') {
                        if (_form.attr('method') == "GET") {
                            feature._redirect(_form.attr('action') + '?' + _form.serialize());
                        } else {
                            _form.submit();
                        }
                        return;
                    } else {
                        _form.submit(function(e) {
                            e.preventDefault();
                            return;
                        });
                    }

                    e.preventDefault();

                    if (_this.attr('data-event') == 'change') {
                        _data = { 'data': _this.val() };
                    }


                    if (typeof _form[0] == 'object') {
                        _data = _form.serialize();
                    }

                    if (_this.data('inner') == 'response') {
                        _data = $(_response).find('input, select').serialize();
                    }

                    if (_this.data('inner') == 'append') {
                        _body = _response.first().find('[data-role="append"]').first();
                        _data = $(_body).find('input, select').serialize();
                    }

                    if (!feature._hover_flag) {
                        NProgress.start();

                        $('.modal-backdrop, .modal').css('z-index: -1; display:none;');

                        // 						_this.removeAttr('data-role');

                        if (_this.data('pass')) {
                            _form.submit();
                        }

                        feature._hover_flag = true;
                        $.ajax({ url: _route, data: _data, type: 'POST' }).done(function(_ajax_response) {
                            // ByMC TM: for paypal button
                            if (_this.hasClass('btn-payment')) {
                                if (!_ajax_response.error) {
                                    //first do confirm. then seldf_form
                                    var ppl = document.getElementsByName('paypal_form');
                                    //console.log(ppl);
                                    ppl[0].submit();

                                    return false;
                                }
                            }


                            if (typeof _ajax_response == 'string') {
                                NProgress.done();
                                _response.html(_ajax_response);
                                feature._set_actions(_response);
                                return;
                            }

                            if (_ajax_response.redirect) {

                                $('.modal').modal('hide');

                                if (_ajax_response.html) {
                                    NProgress.done();

                                    if (_append != '#message') {
                                        if (_append == 'response') {
                                            _body = _this.parents("[data-role=response]").first().find('[data-role="append"]').first();

                                            _body.append(feature._htmlDecode(_ajax_response.html));

                                            feature._set_actions(_body);
                                            return;
                                        } else {
                                            _body = $(_append + ' div.modal-wrapper');
                                        }
                                    } else
                                        _body = $(_append + ' div[class=modal-body]');



                                    _body.html(feature._htmlDecode(_ajax_response.html));
                                    //$(_append+' div.modal-wrapper').html(htmlDecode(_ajax_response.html));
                                    $(_append).modal('show');

                                    $(_append).on('hidden.bs.modal', function() {
                                        feature._redirect(_ajax_response.redirect);
                                    });

                                    if (_append == '#loadModal') {
                                        if (_this.data('draggable') == true) {
                                            $(_append).draggable({
                                                handle: ".modal-wrapper"
                                            });
                                            $('.modal-backdrop').css('display', 'none');
                                        }
                                    }


                                    feature._set_actions(_body);
                                    //feature._set_actions($(_append+' div.modal-wrapper'));

                                    return false;
                                } else if (_ajax_response.message) {
                                    //Modal
                                    NProgress.done();

                                    $('#message #text').html(_ajax_response.message);
                                    $('#message').modal('show');
                                    $('#message').on('hidden.bs.modal', function() {
                                        feature._redirect(_ajax_response.redirect);
                                    });

                                } else {

                                    if (_route.indexOf("#stripe") != -1) {
                                        NProgress.done();

                                        $('#customButton').attr('data-route', _ajax_response.redirect);
                                        $('#customButton').click();
                                    } else {
                                        feature._redirect(_ajax_response.redirect);
                                    }


                                }
                            }

                            feature._set_actions(_response);
                        }).fail(function() {
                            feature._hover_flag = false;

                            $.ajax({ url: '/fails/500/modal', type: 'POST' }).done(function(_ajax_response) {
                                $('#message #text').html(feature._htmlDecode(_ajax_response.html));
                                $('#message').modal('show');
                                NProgress.done();
                            });
                        });
                    }
                });
            });
        },
        _on_change_select: function(element) {
            element.on('change', function() {
                var _this = $(this);
                var _value = _this.val();
                var _route = _this.data('route');
                var _select_state = _this.data('change');
                var _placeholder = $('.select-' + _select_state).find('input').attr('placeholder');
                var _html = $('<input>').attr({ 'type': 'text', 'name': 'state', 'class': 'form-control', 'placeholder': _placeholder });

                $.ajax({ url: _route, data: { value: _value }, type: 'POST' }).done(function(_response) {
                    var _data = _response.data;
                    var _option = '';

                    if (_response.data) {
                        _option += '<option value="">* State</option>';
                        $.each(_data, function(index, value) {
                            _option += '<option value="' + index + '">' + value + '</option>';
                        });
                        _html = '<select name="' + _select_state + '" class="form-control">' + _option + '</select>';
                    }

                    $('.select-' + _select_state).html(_html);

                    if (_response.zip_code) {
                        stripe_settings.zipCode = _response.zip_code;
                        // document.getElementById('customButton').addEventListener('click', function(e) {
                        //                  handler.open(stripe_settings);
                        //                  e.preventDefault();
                        //                });

                    }

                }).fail(function() {
                    $.ajax({ url: '/fails/500/modal', type: 'POST' }).done(function(_ajax_response) {
                        $('#message #text').html(feature._htmlDecode(_ajax_response.html));
                        $('#message').modal('show');
                        NProgress.done();
                    });
                });
            });

        },
        _on_change_email: function(element) {
            element.on('keyup', function() {
                var _this = $(this);
                var _route = _this.data('route');
                var _email = _this.val();
                var _error = _this.parent().find('label.error-db');

                delay(function() {

                    $.get(_route, { 'email': _email }, function(response) {
                        var _result = response.data;
                        if (_result.exists == true) {
                            if (typeof _error[0] != 'object') {
                                _this.parent().append('<label class="error-db">' + _result.message + '</label>');
                                _this.on('focus', function() {
                                    _error.remove();
                                });

                            }
                        } else {
                            _error.remove();
                        }

                    });
                }, 500);

            });
            var delay = (function() {
                var timer = 0;
                return function(callback, ms) {
                    clearTimeout(timer);
                    timer = setTimeout(callback, ms);
                };
            })();

        },
        _apply_card_validation: function(element) {
            var last_valid = '';
            if (typeof element[0] == 'object') {
                element.validateCreditCard(function(result) {
                    if (result.card_type != null) {
                        last_valid = result.card_type.name + " valid";
                        this.addClass(last_valid);
                    } else {
                        this.removeClass(last_valid);
                    }
                });
            }
        },
        _apply_masked_input: function(element) {
            $.each(element, function() {
                var _this = $(this);

                var _placeholder = _this.data('placeholder');
                var _type = _this.data('mask-type');
                var _mask = '';


                if (_type == 'expiration') {
                    _mask = "9999/99";
                } else if (_type == 'date') {
                    _mask = "9999/99/99";
                } else if (_type == 'date_format') {
                    _mask = "99/99/9999";
                } else if (_type == 'expiry_month') {
                    _mask = "99";
                } else if (_type == 'expiry_year') {
                    _mask = "9999";
                } else if (_type == 'card_number') {
                    _mask = "9999 9999 9999 9999";
                } else if (_type == 'celular') {
                    _mask = "(999) 999-9999"
                }

                console.log(typeof _placeholder);
                if (typeof _placeholder != 'undefined') {
                    _this.mask(_mask, { placeholder: _placeholder });
                } else {
                    _this.mask(_mask);
                }
            });
        },
        _apply_number_change: function(element) {
            $.each(element, function() {
                var _this = $(this);
                var _route = _this.data('route');
                var _on = _this.data('event');
                var _response = _this.closest('div[data-role=response]');
                /*

                				if(_route){
                */
                _this.on('keyup', function(e) {
                    e.preventDefault();
                    var _data = {};
                    if (e.keyCode == 13) {
                        var _data = { 'value': $(this).val() };

                        if (_on == 'change') {
                            /*
                            								_data = $( this ).closest('form').serialize();
                            								$( this ).closest('form').submit(function(e){
                            									e.preventDefault();
                            								});
                            */
                            var _form = $(this).closest('form');
                            _data = _form.serialize();

                            _form.submit(function(e) {
                                e.preventDefault();
                                return;
                            });
                        }

                        NProgress.start();

                        $.ajax({ url: _route, data: _data, type: 'POST' }).done(function(_ajax_response) {
                            NProgress.done();
                            if (_ajax_response.redirect) {
                                window.location = _ajax_response.redirect;
                            } else {
                                _response.html(_ajax_response)
                            }
                            feature._set_actions(_response);
                            return;
                        }).fail(function() {
                            $.ajax({ url: '/fails/500/modal', type: 'POST' }).done(function(_ajax_response) {
                                $('#message #text').html(feature._htmlDecode(_ajax_response.html));
                                $('#message').modal('show');
                                NProgress.done();
                            });
                        });
                        return;
                    }

                });
                //   }
                return;
            });
        },
        _enable_fund: function(element) {
            element.on('change', function() {
                var _this = $(this);
                var _amount_input = $('input[name="amount"]');
                var _disabled = _this.val() == 1 ? false : true;
                var _value = _disabled == true ? '0.00' : '0.00';
                _amount_input.val(_value);
                _amount_input.attr('placeholder', _value);
                _amount_input.prop('disabled', _disabled);
            });
        },
        _change_language: function(element) {
            element.on('change', function() {
                var _this = $(this);
                var _route = _this.data('route');
                var _response = _this.closest('div[data-role=response]');
                var _form = _this.parents('form');

                var _data = {};

                if (typeof _form[0] == 'object') {
                    _data = _form.serialize();
                }

                NProgress.start();
                $.ajax({ url: _route, data: _data, type: 'POST' }).done(function(_response) {
                    if (!_response.error) {
                        NProgress.done();
                        window.location.href = _response.redirect;
                    }
                });

            });
        },
        _apply_bonus: function(element) {
            element.on('change', function() {
                var _this = $(this);
                var _response = _this.closest('div[data-role=response]');
                var _form = _this.parents('form');
                var _route = _form.attr('action');

                if (typeof _form[0] == 'object') {
                    _data = _form.serialize();
                }

                if (_route) {
                    NProgress.start();

                    $.ajax({ url: _route, data: _data, type: 'POST' }).done(function(_ajax_response) {
                        NProgress.done();
                        _response.html(_ajax_response);
                        feature._set_actions(_response);
                    }).fail(function() {
                        $.ajax({ url: '/fails/500/modal', type: 'POST' }).done(function(_ajax_response) {
                            $('#message #text').html(feature._htmlDecode(_ajax_response.html));
                            $('#message').modal('show');
                            NProgress.done();
                        });
                    });


                }
            });
        },
        _apply_anchor: function(element) {
            element.on('click', function() {
                var _this = $(this);
                var _name = $(this).data('anchor');
                var _top = $('div.header').height();
                $("html,body").animate({ scrollTop: $("div[data-id=" + _name + "]").offset().top - _top }, "500");
            });
        },
        _remove: function(element) {
            element.on('click', function() {
                var _this = $(this);
                //Section to remove
                $(this).parents('[data-role="response"]').first().remove();
            });
        },
        _redirect: function(redirect) {
            if (redirect != "#") {

                var redirect_process = {
                    start_progress: function() {
                        NProgress.start();
                        return this;
                    },
                    redirect_page: function(redirect) {
                        var loc = redirect;

                        var _redirect;

                        _redirect = loc.indexOf('?') >= 0 ? redirect + '&ts=' + Date.now() : redirect + '?ts=' + Date.now();

                        if (_redirect.indexOf("#execute") != -1) {
                            NProgress.done();
                        }

                        setTimeout(function() {
                            window.location.href = _redirect;
                        }, 1000);

                        return this;
                    }
                };
                redirect_process.start_progress().redirect_page(redirect);
            } else {
                NProgress.done();
            }
        },
        _htmlDecode: function(input) {
            return $('<div>').html(input).text();
            /*
            		  var e = document.createElement('div');
            		  e.innerHTML = input;
            		  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
            */
        },
        _everyMinute: function(fn) {
            var now = new Date();
            var delay = 15 * 1000; // 1 min in msec
            var start = delay - (now.getSeconds()) * 1000 + now.getMilliseconds();

            setTimeout(function() {
                setInterval(fn, delay);
                // 			  ajaxRequest;
            }, start);
        },
        _make_request: function(route, data, modal_name) {
            var modal_name = modal_name == null ? null : modal_name;
            $.ajax({ url: route, data: data, type: 'POST' }).done(function(_ajax_response) {

                if (_ajax_response.redirect) {

                    if (_ajax_response.html) {
                        NProgress.done();

                        if (typeof modal_name == 'string') {
                            if ($('body').find(modal_name + ' .modal-body')[0]) {
                                $(modal_name + ' .modal-body').html(htmlDecode(_ajax_response.html));
                                $(modal_name).modal('show');
                                $(modal_name).on('hidden.bs.modal', function() {
                                    window.location.href = _ajax_response.redirect;
                                });
                                feature._set_actions($(modal_name + ' .modal-body'));
                            } else {
                                $(modal_name).html(htmlDecode(_ajax_response.html));
                                feature._set_actions($(modal_name + ' .modal-body'));
                            }

                            return false;
                        }
                        $('#message div[class=modal-body]').html(htmlDecode(_ajax_response.html));
                        $('#message').modal('show');
                        $('#message').on('hidden.bs.modal', function() {

                            window.location.href = _ajax_response.redirect;
                        });

                        function htmlDecode(input) {
                            var e = document.createElement('div');
                            e.innerHTML = input;
                            return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
                        }

                        feature._set_actions($('#message div[class=modal-body]'));

                        return false;
                    } else {
                        feature._redirect(_ajax_response.redirect);
                    }
                }
            });
        },
    };

    $.fn.inspira = function(method) {
        if (feature[method]) return feature[method].apply(this, Array.prototype.slice.call(arguments, 1));
        if ("object" === typeof method || !method) return feature._init.apply(this, arguments);
        $.error("Method " + method + " does not exist on jQuery.inspira");
    }

    function Validate(event) {
        var regex = new RegExp("[0-9-+-]");
        var key = String.fromCharCode(event.charCode ? event.which : event.charCode);
        if (!regex.test(key) && event.which != 8 && event.which != 0) {
            event.preventDefault();
            return false;
        }
    }

    function checkSearch(filter_by) {
        if (filter_by == 'destination') {
            if ($('#autocomplete').hasClass('hidden')) {
                $('#autocomplete').removeClass('hidden')
            }
            $('#autocomplete').show();
            $('#autocompletewl').val('');
            $('#autocompletewl').hide();
        }
        if (filter_by == 'resort') {
            if ($('#autocompletewl').hasClass('hidden')) {
                $('#autocompletewl').removeClass('hidden')
            }
            $('#autocomplete').val('');
            $('#autocomplete').hide();
            $('#autocompletewl').show();
        }
    }

    function countTags(num, element) {
        if (num == 0) {
            $(element).attr('disabled', true);
            $(element).css('background-color', '#cccccc');
            $(element).css('cursor', 'not-allowed');
        } else {
            $(element).attr('disabled', false);
            $(element).css('background-color', '#465664!important');
            $(element).css('cursor', 'pointer');
        }
    }


})(jQuery);